import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { WeightPipe } from 'src/app/pipes/weight.pipe';
import { CalculatorService } from 'src/app/services/calculator/calculator.service';
import {
  OrientationPurpose,
  Stage,
} from 'src/app/services/calculator/models/calculator-clinical-characteristics-model';
import { PetModel } from 'src/app/services/pet/models/pet-model';
import { PetPhysicalActivityMaximumTreamentPeriod, PetPhysicalActivityModel } from 'src/app/services/pet/models/pet-physical-activity-model';
import { PhysicalActivityEnum } from '../../enums/physical-activity.enum';
import { SpecieEnum } from '../../enums/specie.enum';
import {
  ObesityTreatment
} from '../../../../services/calculator/models/calculator-product-result-model';
import { Score } from '../../../../services/score/model/score.model';
import {OrientationService} from "../../../../services/orientation/orientation.service";
import { OrientationPurposeEnum } from "../../enums/orientation-purpose.enum";

@Component({
  selector: 'app-clinical-characteristics',
  templateUrl: './clinical-characteristics.component.html',
  styleUrls: ['./clinical-characteristics.component.scss'],
})
export class ClinicalCharacteristicsComponent implements OnInit, OnChanges {
  @Input() petSelected: PetModel;
  @Input() nutritionalGuidanceForm: UntypedFormGroup;
  @Input() obesityTreatment: ObesityTreatment;
  @Input() afterSixtyDaysTreamentPeriod: boolean;

  @Output() validPetData = new Subject<boolean>();
  @Output() goalOrientationSelected = new EventEmitter <OrientationPurpose>;
  dataList: OrientationPurpose[];
  goalOrientation: OrientationPurpose = null;
  physicalActivitiesLabel = '';
  stageDiseaseLabel = 'Estágio da Doença';
  stagesDisease: Stage[];
  weightPet = '';
  physicalActivities: PetPhysicalActivityModel[];
  specieSelected: SpecieEnum;
  mobileScreen: boolean;
  screenWidth: number;
  showScore = false;
  petSelectedId = 0;
  purposeId?: number;
  activityLevel?: number;
  petWeight: number;
  autoComplete: boolean = false;
  physicalActivitiesByMaximumPeriod: PetPhysicalActivityModel[] = PetPhysicalActivityMaximumTreamentPeriod;

  constructor(
    private calculatorService: CalculatorService,
    private orientationService: OrientationService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.getScreenSize();
    this.purposeId = history?.state?.orientationPurposeId;
    this.activityLevel = history?.state?.activityLevel;
    this.petWeight = history?.state?.petWeight;
    history.replaceState(0, "");
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.petSelected) {
      this.weightPet = '';
      this.resetForm();
      this.getData();
      this.validatePetData();
    }
    if(changes && this.afterSixtyDaysTreamentPeriod) {
      this.setCalculatorMaximumTreatmentPeriodRule();
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: number): void {
    this.screenWidth = window.innerWidth;
    this.screenWidth > 576
      ? (this.mobileScreen = false)
      : (this.mobileScreen = true);
  }

  get customCss(): string {
    if (!this.goalOrientation && this.screenWidth > 1440) {
      return 'field-goal-orientation-large';
    } else { return ''; }
  }

  setWeight(event: any): void {
    this.weightPet = new WeightPipe().convertWeight(event.target.value);
    this.nutritionalGuidanceForm.get('peso').setValue(this.weightPet);
    this.changeDetectorRef.detectChanges();
    this.validatePetData();
  }

  getData(): void {
    if (this.petSelected) {
      this.specieSelected =
        this.petSelected.petEspecie === 'Dog' ? SpecieEnum.DOG : SpecieEnum.CAT;
      this.petSelectedId = this.petSelected.id;
      this.calculatorService
        .getOrientationClinicalCharacteristcs(this.specieSelected, this.petSelectedId, this.petSelected.racaId, new Date())
        .subscribe((res) => {
          this.dataList = this.calculatorService.filterClinicalCaracteristcs(this.petSelected.genero, res);
          if(this.purposeId && this.dataList.filter(x => x.id == this.purposeId).length > 0 && !this.autoComplete)
          {
            this.goalOrientation = this.dataList.filter(x => x.id == this.purposeId)[0];
            this.handlePurposeSelected();
            if(this.activityLevel && !this.autoComplete)
            {
              this.handleActivitySelected(this.activityLevel);
              this.autoComplete = true;
              this.weightPet = new WeightPipe().convertWeight(this.petWeight.toString());
              this.nutritionalGuidanceForm.get('peso').setValue(this.weightPet);
              this.validatePetData();
              this.nutritionalGuidanceForm.get('nivelAtividade').addValidators([Validators.required]);
            }
            else if (!this.activityLevel) {
              this.nutritionalGuidanceForm.get('nivelAtividade').clearValidators();
            }
            this.nutritionalGuidanceForm.get('nivelAtividade').updateValueAndValidity();
          }
          else {
            this.purposeId = null;
            this.activityLevel = null;
          }
        });
    } else {
      this.goalOrientation = null;
      this.dataList = null;
    }
  }

  handlePurposeSelected(): void {
    this.nutritionalGuidanceForm
      .get('orientationPurposeId')
      .setValue(this.goalOrientation.id);
    this.nutritionalGuidanceForm
      .get('orientationPurposeName')
      .setValue(this.goalOrientation.name);
    this.physicalActivities =
      this.goalOrientation.physicalActivities?.activities;
    this.physicalActivitiesLabel =
      this.goalOrientation.physicalActivities?.title;
    this.resetForm();
    this.weightPet = null;
    this.stagesDisease = this.goalOrientation?.stages ?? [];
    this.goalOrientation.stages?.length > 0 ? this.nutritionalGuidanceForm.get('stageDisease').setValidators([Validators.required]) : this.nutritionalGuidanceForm.get('stageDisease').setValidators([]);
    this.goalOrientationSelected.emit(this.goalOrientation);
    this.changeDetectorRef.detectChanges();
    this.validatePetData();
    if(this.afterSixtyDaysTreamentPeriod) {
      this.setCalculatorMaximumTreatmentPeriodRule();
    }
  }

  handleActivitySelected(activityId: PhysicalActivityEnum): void {
    this.nutritionalGuidanceForm.get('nivelAtividade').setValue(activityId);
    this.changeDetectorRef.detectChanges();
    this.validatePetData();
  }

  handleStageSelected(stage: number): void {
    this.nutritionalGuidanceForm.get('stageDisease').setValue(stage);
    this.changeDetectorRef.detectChanges();
    this.validatePetData();
  }

  handleScoreSelected(score: Score): void {
    this.nutritionalGuidanceForm.get('score').setValue(score?.order);
    this.nutritionalGuidanceForm.get('scoreName').setValue(score?.name);
    this.nutritionalGuidanceForm.get('scoreBarImageUrl').setValue(score?.barUrl);
    this.nutritionalGuidanceForm.get('scoreImageUrl').setValue(score?.url);
    this.changeDetectorRef.detectChanges();
    this.validatePetData();
  }

  validatePetData(): void {
    const weight = this.nutritionalGuidanceForm.controls.peso.value;
    const activityLevel = this.nutritionalGuidanceForm.controls.nivelAtividade.value;
    const orientationPurpose = this.nutritionalGuidanceForm.controls.orientationPurposeId.value;
    const stageDisease = this.nutritionalGuidanceForm.controls.stageDisease.value;
    const score = this.nutritionalGuidanceForm.controls.score.value;

    if (activityLevel === PhysicalActivityEnum.WEIGHT_REDUCTION_RETURN &&
      weight == null) {
      this.orientationService.getOrientationObesityTreatment(this.petSelectedId).subscribe( resp => {
        this.obesityTreatment = new ObesityTreatment();
        this.obesityTreatment.targetWeight = resp.targetWeight;
        this.obesityTreatment.weightToLose = resp.weightToLose;
        this.obesityTreatment.monthlyProgress = resp.monthlyProgress;
        this.obesityTreatment.treatmentDurationInMonths = resp.treatmentDurationInMonths;
        this.obesityTreatment.dailyKcal = resp.dailyKcal;
        this.obesityTreatment.isReturn = resp.isReturn;
        this.obesityTreatment.isComplete = resp.isComplete;
      });
      this.showScore = true;
      this.validPetData.next(false);
    } else if (
      orientationPurpose &&
      weight &&
      (!this.physicalActivities?.length || activityLevel != null) &&
      (!this.stagesDisease?.length || stageDisease != null)
    ) {
      this.showScore = true;
      if (score != null) {
        this.validPetData.next(true);
      }
    }

    else {
      this.showScore = false;
      this.validPetData.next(false);
    }
  }

  resetForm(resetWeight = true): void {
    this.nutritionalGuidanceForm.get('nivelAtividade').setValue(null);
    this.nutritionalGuidanceForm.get('stageDisease').setValue(null);
    this.nutritionalGuidanceForm.get('score').setValue(null);
    this.nutritionalGuidanceForm.get('scoreName').setValue(null);
    this.nutritionalGuidanceForm.get('scoreBarImageUrl').setValue(null);
    this.nutritionalGuidanceForm.get('scoreImageUrl').setValue(null);
    if(resetWeight) {
      this.nutritionalGuidanceForm.get('peso').setValue(null);
    }
  }

  setCalculatorMaximumTreatmentPeriodRule(): void {
    if(this.isObesityOrientation) {
      this.physicalActivities = this.physicalActivitiesByMaximumPeriod;
      this.activityLevel = null;
      this.resetForm(false);
      this.showScore = false;
    }
  }

  get isObesityOrientation(): boolean {
    const orientationPurpose = this.nutritionalGuidanceForm.get('orientationPurposeId').value;
    return orientationPurpose == OrientationPurposeEnum.DOG_OBESITY || orientationPurpose == OrientationPurposeEnum.CAT_OBESITY
  }
}
